export function truncate(text, max_length) {
  if (!max_length) {
    max_length = 120;
  }
  const formatted = text ? text.substring(0, max_length) : "";
  return formatted.length < max_length ? formatted : formatted + "...";
}

export function randomText(min_words, max_words) {
  const text =
    "Lorem ipsum dolor amet consectetur adipiscing elit eiusmod tempor incididunt labore dolore magna aliqua Lorem ipsum dolor amet consectetur adipiscing elit eiusmod tempor incididunt labore dolore magna aliqua Lorem ipsum dolor amet consectetur adipiscing elit eiusmod tempor incididunt labore dolore magna aliqua";
  const words = shuffle(text.split(" "));

  let max = min_words + Math.ceil(Math.random() * (max_words - min_words));
  let str = "";
  for (let i = 0; i < words.length; i++) {
    if (i < max) {
      str += " " + words[i];
    }
  }
  return str;
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}
